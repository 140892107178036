<template>
    <div class="add-friends-container">
        <form class="add-friends" @submit.prevent = 'submitForm' ref="formAddFriends">
            <div class="title">
                Я залучаю друзів
            </div>
            <div class="invitations-sent-row">
                <div class="main">
                    <img src="../assets/images/add-friend.png" alt="add-friends">
                    <div class="description">
                        Відправлено запрошень друзям
                    </div>
                </div>
                <div class="sent-counter">
                    {{inviteCounter}}
                </div>
            </div>
            <div class="inputs-row">
                <div class="input-form">               
                    <label class="label" for="surname">Прізвище</label>
                    <input name="last_name" v-model="last_name" class="input" id="surname" type="text">
                    <span v-if="errors.last_name" class="error-label">{{errors.last_name}}</span>
                </div>
                <div class="input-form">
                    <label class="label" for="name">Ім’я</label>
                    <input v-model="first_name" name="first_name" class="input" id="name" type="text">
                    <span v-if="errors.first_name" class="error-label">{{errors.first_name}}</span>
                </div>
                <div class="input-form">
                    <label class="label" for="patronymic">По батькові</label>
                    <input name="middle_name" v-model="middle_name" class="input" id="patronymic" type="text">
                    <span v-if="errors.middle_name" class="error-label">{{errors.middle_name}}</span>
                </div>
                <div class="input-form">
                    <label class="label" for="email">E-mail</label>
                    <input name="email" v-model="email" class="input" id="email" type="text">
                    <span v-if="errors.email" class="error-label">{{errors.email}}</span>
                </div>
            </div>
            <div class="list-friends">
                <div class="friends-row" v-for="item in this.inviteList">
                    <div class="input-form">
                        <label class="label">Прізвище</label>
                        <input class="input" type="text" readonly :value="item.last_name">
                    </div>
                    <div class="input-form">
                        <label class="label">Ім’я</label>
                        <input class="input" type="text" readonly :value="item.first_name">
                    </div>
                    <div class="input-form">
                        <label class="label">По батькові</label>
                        <input class="input" type="text" readonly :value="item.middle_name">
                    </div>
                    <div class="input-form">
                        <label class="label">E-mail</label>
                        <input class="input" type="text" readonly :value="item.email">
                    </div>
                </div>
            </div>
            <div class="btn-container">
                <button class="btn-add" type="submit">
                    Залучити
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import InviteService from "@/services/InviteService";

export default {
    name: "FormAddFriends",
    data() {
        return {
            first_name: null,
            email: null,
            last_name: null,
            middle_name: null,
            inviteList: [],
            errors: {
                email: '',
                first_name: '',
                last_name: '',
                middle_name: ''
            },
            userId: null,
            inviteCounter: 0
        }
    },
    watch:{
      userId(){
          this.getInviteCounter()
      }
    },
    methods:{
        async submitForm(){
            let formData = new FormData(this.$refs.formAddFriends);

            let resp = await InviteService.uploadInvite(formData).catch(this.handleError)
            if (resp?.status === 'success'){
                this.clearForm()
                this.getInviteList()
                this.errors = {}
                ++this.inviteCounter
            }
        },
        handleError(resp){
            this.errors = {}
            if (resp.statusCode === 422) {
                this.errors = resp.data
            }
        },
        clearForm(){
            this.email = null;
            this.first_name = null;
            this.last_name = null;
            this.middle_name = null;
        },
        async getInviteList(){
           this.inviteList = await InviteService.getListInvite().catch(this.handleError)
        },
        async getUserId(){
            this.userId = await InviteService.getUserId().catch(this.handleError)
        },
        async getInviteCounter(){
            this.inviteCounter = await InviteService.getInviteStatistics(this.userId).catch(this.handleError)
        }
    },
    created() {
        this.getInviteList()
        this.getUserId()
    }
}
</script>

<style scoped lang="less">
@text-color: #1a2744;
@yellow-color: #ffc900;

.add-friends-container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    .add-friends {
        background-color: white;
        min-height: 667px;
        border-radius: 10px;
        padding: 50px 50px 40px 50px;
        width: 100%;
        @media (max-width: 992px){
            margin: 0;
        }

        .title {
            color: @text-color;
            font-family: @secondFontFamily;
            font-size: 24px;
            font-weight: 500;
            display: flex;
            justify-content: center;
        }

        .invitations-sent-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 50px;
            width: 100%;

            .main {
                display: flex;
                align-items: center;

                .description {
                    font-family: @secondFontFamily;
                    font-size: 16px;
                    font-weight: 500;
                    color: @text-color;
                }

                img {
                    margin-right: 20px;
                }
            }

            .sent-counter {
                font-family: @secondFontFamily;
                font-size: 56px;
                font-weight: 500;
                color: @text-color;
            }
        }

        .inputs-row {
            margin-top: 50px;
            display: flex;
            margin-bottom: 40px;
            width: 100%;

            @media (max-width: 768px){
                flex-direction: column;
            }

            .input-form {
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                width: 27%;
                position: relative;

                @media (max-width: 768px){
                    width: 100%;
                    margin-bottom: 20px;
                }

                &:last-child {
                    padding-left: 30px;
                    @media (max-width: 768px){
                        padding-left: 0;
                    }
                }

                .label {
                    font-size: 12px;
                    color: @text-color;
                    margin-bottom: 10px;
                }

                .input {
                    outline: none;
                    //width: 260px;
                    width: 100%;
                    height: 50px;
                    border-radius: 10px;
                    border: solid 1px #cdd4e0;
                    background-color: #fff;
                    padding-left: 20px;
                    font-size: 16px;
                    font-weight: 500;
                    color: @text-color;

                    &:focus {
                        border: solid 1px @yellow-color;
                    }
                }
            }
        }

        .list-friends{
            max-height: 180px;
            overflow-y: scroll;
            overflow-x: hidden;
            margin-right: -20px;
            width: 100%;

            @media screen and (max-width: 768px){
                max-height: 100% !important;
                overflow-y: auto
            }


            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: @yellow-color;
                border-radius: 10px;
            }

            .friends-row {
                display: flex;
                margin-top: 10px;

                @media screen and (max-width: 768px){
                    flex-direction: column;
                    border-top: 1px solid lightgray;
                    padding-top: 20px;
                }

                .input-form{
                    display: flex;
                    flex-direction: column;
                    padding-right: 10px;
                    width: 26%;

                    &:last-child {
                        padding-left: 30px;

                        @media screen and (max-width: 768px){
                            padding-left: 0;
                        }
                    }

                    @media screen and (max-width: 768px){
                        width: 100%;
                        margin-bottom: 20px;
                        padding-right: 0;
                    }

                    .label {
                        font-size: 12px;
                        color: #1a2744;
                        margin-bottom: 10px;

                        @media screen and (min-width: 768px){
                            display: none;
                        }
                    }

                    .input{
                        outline: none;
                        //width: 260px;
                        width: 100%;
                        height: 50px;
                        border-radius: 10px;
                        border: solid 1px #cdd4e0;
                        background-color: #fff;
                        padding-left: 20px;
                        font-size: 16px;
                        font-weight: 500;
                        color: @text-color;
                        display: block;

                        &:focus {
                            border: solid 1px #ffc900;
                        }
                    }
                }
            }
        }
        .btn-container{
            display: flex;
            justify-content: center;
            margin-top: 50px;
            .btn-add{
                height: 50px;
                width: 220px;
                border-radius: 10px;
                box-shadow: 0 24px 24px -14px rgba(26, 39, 68, 0.35);
                background-color: #1a2744;
                font-size: 18px;
                font-weight: 600;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: @secondFontFamily;
                cursor: pointer;
            }
        }
    }
    .error-label{
        font-size: 12px;
        color: #ffc900;
        height: 0;
        display: block;
        position: absolute;
        bottom: 0;
    }
}
</style>
