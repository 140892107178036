import axios from "axios";
import handleApiError from "@/services/handleApiError";

export default {
    uploadInvite(form) {
        return axios.post('/api/invite/create', form).then(resp => resp.data).catch(handleApiError);
    },
    getListInvite() {
        return axios.get('/api/invite').then(resp => resp.data.data).catch(handleApiError);
    },
    getUserId() {
        return axios.get('/api/user').then(resp => resp.data.user.id).catch(handleApiError);
    },
    getInviteStatistics(id) {
        return axios('/api/invite/statistics', {params : {user_id : id}}).then(resp => resp.data.data.all).catch(handleApiError)
    },
    getInviteDetail(id){
        return axios('/api/invite/statistics', {params : {user_id : id}}).then(resp => resp.data.data).catch(handleApiError)
    },
    getDetailUser(){
        return axios.get('/api/user').then(resp => resp.data.user).catch(handleApiError);
    },
    getUserNotDeputies(){
        return axios.get('/api/user/deputies?is_deputy=0').then(resp => resp.data.data.items ).catch(handleApiError);
    }
}
