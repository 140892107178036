<template>
    <div class="add-friends-container">
      <CommonBlock
          title=""
          :visible-back-btn="true"
          :cancelBackBtnEventPassed="true"
          @clickBack="clickBack"
          class="calendar-common"
      >
          <div class="description">
              Європейська Солідарність не просто партія, а велика спільнота однодумців. А головне – сім’я. <br> <br>
              Долучай друзів до нас!
          </div>
        <div class="img">
          <img src="@/assets/images/1_friends.svg" alt="svg">
<!--            <img src="../assets/images/6.png" alt="add friends">-->
        </div>
      </CommonBlock>
        <div class="add-friends">
            <FormAddFriends></FormAddFriends>
        </div>
    </div>
</template>

<script>
import FormAddFriends from "../components/FormAddFriends";
import CommonBlock from "../components/CommonBlock";

export default {
    name: "AddFriends",
    components: {
        FormAddFriends,
        CommonBlock
    },

    methods: {
        clickBack() {
            this.$router.push({ name: 'profile' })
        }
    }
}
</script>

<style scoped lang="less">
.add-friends-container{
  display: flex;
    @media (max-width: 992px) {
        flex-direction: column;
    }
}
.add-friends{
    width: 100%;
}
::v-deep{
    .common-block{
        height: auto;
        .title{
            display: none;
        }
        .description{
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.13px;
            color: #fff;
            font-family: @defaultFontFamily;
            margin-bottom: 22px;
            margin-top: -20px;
        }
    }

}
</style>
